<template>
  <div class='mainBody'>
    <el-form ref="form" :model="siteInfo"  size="small" label-width="120px">
      <el-row style="width:1000px;">
        <el-col :span="8">
          <el-form-item label="站点ID" prop="id">
            <el-input v-model="siteInfo.siteId" style="width: 210px;"  :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="站点名称" prop="id">
              <el-input v-model="siteInfo.siteName" style="width: 210px;"  :disabled="true"/>
            </el-form-item>
        </el-col>
        <el-col :span="8">
             <el-form-item label="站点域名" prop="id">
              <el-input v-model="siteInfo.domainName" style="width: 210px;"  :disabled="true"/>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:1000px;">
        <el-col :span="8">
          <el-form-item label="愿景" prop="mission">
            <el-input  v-model="siteInfo.mission" style="width: 210px;"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="目标" prop="goal">
              <el-input   v-model="siteInfo.goal" style="width: 210px;"/>
            </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="使命" prop="expectation">
              <el-input  v-model="siteInfo.expectation" style="width: 210px;"/>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:1000px;">
        <el-col :span="8">
          <el-form-item label="客服QQ" prop="qqCustService">
            <el-input v-model="siteInfo.qqCustService" style="width: 210px;"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="客服电话" prop="custServicePhone">
              <el-input v-model="siteInfo.custServicePhone" style="width: 210px;"/>
            </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="工作时间" prop="workTime">
              <el-input v-model="siteInfo.workTime" style="width: 210px;"/>
            </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="社区主题" prop="themeType">
        <el-radio-group v-model="siteInfo.themeType" size='medium'>
          <el-radio-button border label="1">红色</el-radio-button>
          <el-radio-button border label="2">橙色</el-radio-button>
          <el-radio-button border label="3">黄色</el-radio-button>
          <el-radio-button border label="4">绿色</el-radio-button>
          <el-radio-button border label="5">蓝色</el-radio-button>
          <el-radio-button border label="6">其他</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="3">
          <el-form-item label="主题色" prop="themeColor">
            <el-input  v-model="siteInfo.themeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
            <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.themeColor"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="第二主题色" prop="secondthemeColor">
            <el-input  v-model="siteInfo.secondthemeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
            <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.secondthemeColor"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
           <el-form-item label="导航背景" prop="headerBgc">
            <el-input  v-model="siteInfo.headerBgc" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
            <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.headerBgc"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="底部背景" prop="footerBgc">
            <el-input  v-model="siteInfo.footerBgc" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
            <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.footerBgc"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
           <el-form-item label="侧边栏背景色" prop="rightBarBackgroundColor">
            <el-input  v-model="siteInfo.rightBarBackgroundColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
            <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.rightBarBackgroundColor"></el-color-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="主题示例图片" prop="">
        <img v-if="siteInfo.themeType!=6" :src="exampleImg" style="width: 1000px;height:343px;" >
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="导航栏logo" prop="navibarLogo">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-success="handleSuccess"
              :file-list="fileLists"
              list-type="picture"
              :show-file-list='false'>
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <img v-if="siteInfo.navibarLogo" :src="siteInfo.navibarLogo" class="logo-class">
          </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="底部导航栏logo" prop="footBarLogo">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-success="handleSuccess1"
                :file-list="fileLists"
                :show-file-list='false'
                list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <img v-if="siteInfo.footBarLogo" :src="siteInfo.footBarLogo" class="foot-logo-class">
            </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="客服微信二维码" prop="weixinQcodeCustService">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-success="handleSuccess2"
              :file-list="fileLists"
              :show-file-list='false'
              list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <img v-if="siteInfo.weixinQcodeCustService" :src="siteInfo.weixinQcodeCustService" class="weixinQcodeCust-logo-class">
          </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公众号二维码" prop="weixinQcodePub">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-success="handleSuccess3"
                :file-list="fileLists"
                :show-file-list='false'
                list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <img v-if="siteInfo.weixinQcodePub" :src="siteInfo.weixinQcodePub" class="weixinQcodePub-logo-class">
            </el-form-item>
          </el-col>
      </el-row>
      <el-form-item label="公司简介" prop="companyIntro">
        <el-input type="textarea"  v-model="siteInfo.companyIntro" style="width: 800px;"/>
      </el-form-item>
      <el-form-item label="团队介绍" prop="teamIntro">
        <el-input type="textarea"  v-model="siteInfo.teamIntro" style="width: 800px;"/>
      </el-form-item>
      <el-form-item label="cnzz统计代码" prop="statisticsCode">
        <el-input type="textarea" v-model="siteInfo.statisticsCode" style="width: 800px;"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="siteInfo.remark" style="width: 800px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="text" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit()">确认</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import { add , edit, getSiteConfig } from "@/api/cms/srSiteConfig";
export default {
  data() {
    return {
      loading:false,
      fileLists:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      siteId:'',
      exampleImg:'',
      siteInfo:{
        id: -99,
        siteId: -99,
        siteName: "",
        domainName: "",
        companyIntro: " ",
        teamIntro: " ",
        mission: " ",
        goal: " ",
        expectation: " ",
        qqCustService: "",
        custServicePhone: "",
        workTime: "",
        navibarLogo: "",
        icon: "",
        weixinQcodePub: "",
        weixinQcodeCustService: "",
        footBarLogo: "",
        custServiceEmail: "",
        bannerBg: "",
        themeColor: "#FD5E17",
        themeType: 1,
        bannerBg:'',
        secondThemeColor: "#FF7539",
        headerBgc: "#333333",
        footerBgc: "#454545",
        rightBarBackgroundColor: "#999999",
        statisticsCode: "",
        language: ""
        },
      loading:false,
      themeColors:[
        {
          themeColor: "#E74C3C",
          secondthemeColor: "#FA6758",
          headerBgc: "#333333",
          footerBgc: "#454545",
          rightBarBackgroundColor: "#E74C3C",
          bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/2dd169f025984fc983b56ae9f9adbaaa.jpg",
          exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/ec0d93685b264f6fbe4ed16864dbc9f0.png"
        },
        {
          themeColor: "#FA6C0F",
          secondthemeColor: "#FF8536",
          headerBgc: "#333333",
          footerBgc: "#454545",
          rightBarBackgroundColor: "#FFBA02",
          bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/cda132da9fe945bebc04ac683a5a5a44.jpg",
          exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/85875d39061d46c4864e054a96ad104f.png"

        },
        {
          themeColor: "#FFBA02",
          secondthemeColor: "#FFD053",
          headerBgc: "#333333",
          footerBgc: "#454545",
          rightBarBackgroundColor: "#E74C3C",
          bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/ac82717994c04508935e1c89ea2efb3d.jpg",
          exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1e42dfbfefbc4108bdf16cd8ba815309.png"

        },
        {
          themeColor: "#11AC80",
          secondthemeColor: "#46D3AB",
          headerBgc: "#353938",
          footerBgc: "#414343",
          rightBarBackgroundColor: "#11AC80",
          bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/eafa33139a4e4fb9b00242333fc4a14c.jpg",
          exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/17aa125f2c82428c8b6ba397a0f3a323.png"

        },
        {
          themeColor: "#207AF4",
          secondthemeColor: "#59A0FF",
          headerBgc: "#2A2E32",
          footerBgc: "#33373B",
          rightBarBackgroundColor: "#207AF4",
          bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1a08ec2400c74ad18fa7ef96592de38a.jpg",
          exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1d43083d550b45df9c16a8bd4f940745.png"

        }
      ],
    }
  },
  created() {
    this.siteId = this.$route.query.siteId
    this.siteInfo.siteId = this.siteId
    this.siteInfo.themeColor=this.themeColors[0].themeColor
    this.siteInfo.secondthemeColor=this.themeColors[0].secondthemeColor
    this.siteInfo.headerBgc=this.themeColors[0].headerBgc
    this.siteInfo.footerBgc=this.themeColors[0].footerBgc
    this.siteInfo.rightBarBackgroundColor=this.themeColors[0].rightBarBackgroundColor
    this.bannerBg=this.themeColors[0].bannerBg
    this.exampleImg=this.themeColors[0].exampleImg
    this.getSiteConfigInfo()
  },
  computed: {
    themeType() {
  　　　　return this.siteInfo.themeType
  　　}
  },
  watch:{
    themeType(newValue, oldValue) {
      if(newValue==6){
        this.siteInfo.themeColor=this.themeColors[0].themeColor,
        this.siteInfo.secondthemeColor=this.themeColors[0].secondthemeColor,
        this.siteInfo.headerBgc=this.themeColors[0].headerBgc,
        this.siteInfo.footerBgc=this.themeColors[0].footerBgc,
        this.siteInfo.rightBarBackgroundColor=this.themeColors[0].rightBarBackgroundColor
        this.bannerBg=this.themeColors[0].bannerBg
        this.exampleImg=this.themeColors[0].exampleImg
      }else {
        newValue=newValue-1
        this.siteInfo.themeColor=this.themeColors[newValue].themeColor,
        this.siteInfo.secondthemeColor=this.themeColors[newValue].secondthemeColor,
        this.siteInfo.headerBgc=this.themeColors[newValue].headerBgc,
        this.siteInfo.footerBgc=this.themeColors[newValue].footerBgc,
        this.siteInfo.rightBarBackgroundColor=this.themeColors[newValue].rightBarBackgroundColor
        this.bannerBg=this.themeColors[newValue].bannerBg
        this.exampleImg=this.themeColors[newValue].exampleImg
      }
    }
  },
  methods:{
    getSiteConfigInfo(){
      let params = { siteId: this.siteId };
      getSiteConfig(params).then(res => {
        if(res.data != null){
          this.siteInfo = res.data;
        }

      });
    },
    doSubmit() {
      this.loading = true;
      if (this.siteInfo.id==-99) {
        this.doAdd();
      } else{
        this.doEdit();
      }
    },
    doAdd() {
      add(this.siteInfo)
        .then(res => {
          this.$notify({
            title: res.data.message,
            type: "success",
            duration: 2500
          });
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.siteInfo)
        .then(res => {
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
     },
    handleSuccess(response){
        //上传成功要处理的事
        this.siteInfo.navibarLogo=response.data.content[0].url;
    },
    handleSuccess1(response){
        //上传成功要处理的事
        this.siteInfo.footBarLogo=response.data.content[0].url;
    },
    handleSuccess2(response){
        //上传成功要处理的事
        this.siteInfo.weixinQcodeCustService=response.data.content[0].url;
    },
    handleSuccess3(response){
        //上传成功要处理的事
        this.siteInfo.weixinQcodePub=response.data.content[0].url;
    },
    handleSuccess4(response){
        //上传成功要处理的事
        this.siteInfo.icon=response.data.content[0].url;
    },
    cancel(){
    }
  }
}
</script>

<style scoped>
.el-collapse {
  margin-left: 20px;
  margin-right: 20px;
}
.mainBody{
  margin-left: 20px;
  margin-right: 20px;
  margin-top:20px;
}
.logo-class{
  width:auto;
  height:30px;
}
.foot-logo-class{
  width:auto;
  height:38px;
}
.weixinQcodeCust-logo-class{
  width:75px;
  height:75px;
}
.weixinQcodePub-logo-class{
  width:125px;
  height:125px;
}
</style>
